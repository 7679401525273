import { useQuery } from "@tanstack/react-query";
import Tree from "react-d3-tree";

import { useCenteredTree } from "./hook/useCenteredTree";
import service from "../../service";
import Loading from "../../Component/Loading";

const Member = () => {
  const profile = useQuery<{ _id: string; parent: string }>({
    queryKey: ["profile"],
    queryFn: service.getProfile,
  });

  const downline = useQuery({
    queryKey: ["downline"],
    queryFn: () =>
      service.getDownline({
        focusId: profile?.data?._id as string,
        parentFocusId: profile?.data?.parent as string,
      }),
    enabled: !!profile?.data?._id,
  });

  const { dimensions, translate, containerRef } = useCenteredTree();
  console.log("downline?.data",downline?.data)
  return (
    <div className="flex-1 flex justify-center items-center" ref={containerRef}>
      {downline.isLoading ? (
        <Loading />
      ) :
      downline?.data?.length ?
      (
        <Tree
          collapsible
          initialDepth={2}
          dimensions={dimensions}
          translate={translate}
          data={downline.data}
          orientation="vertical"
        />
      ): (<div></div>)}
    </div>
  );
};

export default Member;
